import ClientSuspense from '@components/ClientSuspense'
import DeliveryWizard from '@components/DeliveryWizard'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import doesCountryRequireCookies from '@helpers/websites/doesCountryRequireCookies'
import useCountryCode from '@hooks/useCountryCode'
import useWebsiteAddressInstructionModal from '@hooks/useWebsiteAddressInstructionModal.main'
import useLayout from '@page-components/Order/useLayout'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'
import React, {useEffect, useState} from 'react'

const CreateAddressInstructions = dynamic(() => import('@components/AddressInstructionsModal'))

function CreateAddressModalInner() {
  const useAddressInstructionsModal = useWebsiteAddressInstructionModal()
  const countryCode = useCountryCode()
  const needCookies = doesCountryRequireCookies(countryCode)
  const userPreferences = useUserPreferences()
  const layout = useLayout()
  const router = useRouter()

  useEffect(() => {
    if (router.query?.addressModal) {
      router.replace({pathname: '/pedir', query: {}})
    }
  }, [router.query?.addressModal])

  const [instructionsModalOpen, setInstructionsModalOpen] = useState<boolean>(
    useAddressInstructionsModal &&
      (!needCookies || (needCookies && layout !== 'mobile')) &&
      !userPreferences?.storeId &&
      !router.query?.placeId,
  )

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <>
      <CreateAddressInstructions
        isOpen={instructionsModalOpen}
        setOpen={setInstructionsModalOpen}
        setOpenAddressModal={setModalOpen}
      />
      <DeliveryWizard.AsModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  )
}

export default function CreateAddressModal() {
  return (
    <ClientSuspense fallback={null}>
      <CreateAddressModalInner />
    </ClientSuspense>
  )
}
